import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import { mutate } from 'swr'
import clsx from "clsx";
import { useSnackbar } from 'notistack'
import { useTranslation } from '@contexts/translation'
import { Typography, IconButton } from '@material-ui/core'
import Scrollbars from '@components/common/Scrollbars'
import { ModalDelete, ModalLotGroup } from '@components/common'
import CircularProgress from '@material-ui/core/CircularProgress'
import { updateLotsGroup, deleteLotGroup } from "@api/lots";
import isEqual from 'lodash/isEqual';
import { numberWithSpacesNoDecimalAdded } from '@utils/numberWithSpaces'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons/';

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  infoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  noMargin: {
    marginBottom: 0,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
    height: '100%'
  },
  building: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  floor: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  lotAlone: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  lotBuildingFloor: {
    cursor: 'pointer',
    marginLeft: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  lotBuilding: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  lotContainer: {
    maxHeight: '900px',
  },
  lotSelected: {
    background: theme.palette.menu.backgroundSettings,
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  lotGroupCard: {
    display: 'flex',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: 'column',
    boxShadow: theme.shadows[25].z16,
    borderRadius: theme.shape.borderRadiusMd,
    width: '100%',
    minWidth: '260px',
    maxWidth: '260px',
    maxHeight: '800px',
    '& .flex': {
      display: 'none'
    },
    '& :hover': {
      '& .flex': {
        display: 'flex',
      }
    },
  },
  infoActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 48,
  },

}))

export default function LotGroupCategoriesView({
  groupId,
  lotGroupLots,
  lots,
  display,
  surface,
  fetchUrlGroup,
  fetchUrl,
  isLoadingLot,
  operationId,
  isLoadingGroup,
}) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [lotsToAdd, setLotsToAdd] = useState([])
  const [isChange, setIsChange] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [idRow, setIdRow] = useState()


  const handleDeleteOpen = (id) => {
    setIsDelete(true)
    setIdRow(id)
  }

  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const handleUpdateOpen = (id) => {
    setIsEdit(true)
    setIdRow(id)
  }

  const handleUpdateClose = () => {
    setIsEdit(false)
  }

  const compareNumber = (a, b) => {
    return a - b
  }

  const getLotId = lotsToAdd.map(lot => {
    if (lot.is_selected && lot.is_selected === true) {
      return lot.id
    }
    else {
      return
    }
  })
  const filteredLot = getLotId.filter(lot => lot !== undefined);
  // UseEffect => put IsSelected to all lots that is already in array
  useEffect(() => {
    setIsChange(true)
    if (lots && lots.length > 0 && lotGroupLots && lotGroupLots.length > 0) {
      let lotsInGroup = lots
      const markLotInGroup = lotGroupLots.map(lot => {
        lots.map((item, index) => {
          if (item.id === lot) {
            lotsInGroup[index].is_selected = true
          }
        })
      })
      setLotsToAdd([...lotsInGroup])
      setIsChange(false)
    }
    if (lots && lots.length > 0) {
      setLotsToAdd([...lots])
      setIsChange(false)
    }
  }, [lots, lotGroupLots]);

  const onDelete = async () => {
    const response = await deleteLotGroup(idRow)

    if (response) {
      if (response.status === 204) {
        mutate(fetchUrl)
        mutate(fetchUrlGroup)
        enqueueSnackbar(dictionary['lot_group_deleted'], {
          variant: 'success',
        })
        handleDeleteClose()
      }
    }
  }


  const onSubmit = async () => {
    setIsLoading(true)
    let data = {}
    const getLotId = lotsToAdd.map(lot => {
      if (lot.is_selected && lot.is_selected === true) {
        return lot.id
      }
      else {
        return
      }
    })
    const filteredLot = getLotId.filter(lot => lot !== undefined);
    data['lots'] = filteredLot
    let response = await updateLotsGroup(data, groupId)
    if (response) {
      if (response.status === 200)
        enqueueSnackbar(dictionary['lot_group_updated'], {
          variant: 'success',
        })
      mutate(fetchUrl)
      mutate(fetchUrlGroup)
      setIsLoading(false)
    }
    else {
      setIsLoading(false)
    }

    // check all lot with IsSelected=true and send them in array
  }

  const onClickBuilding = (buildingId, isUsed) => {
    let lotsArray = lotsToAdd
    const selectLots = lotsToAdd.map((item, index) => {
      if (!isUsed) {
        if (item.id === buildingId) {
          lotsArray[index].is_used = true
        }
        if (item.building === buildingId && item.is_floor) {
          lotsArray[index].is_used = true
        }
        if (item.building === buildingId && !item.is_floor) {
          lotsArray[index].is_selected = true
        }
      }

      else {
        if (item.id === buildingId) {
          lotsArray[index].is_used = false
        }
        if (item.building === buildingId && item.is_floor) {
          lotsArray[index].is_used = false
        }
        if (item.building === buildingId && !item.is_floor) {
          lotsArray[index].is_selected = false
        }
      }
    })
    setLotsToAdd([...lotsArray])

    // filter by building then 'setIsSelected=true" to all item that match 
  }

  const onClickFloor = (floorId, isUsed) => {
    let lotsArray = lotsToAdd

    const selectLots = lotsToAdd.map((item, index) => {
      if (!isUsed) {
        if (item.id === floorId) {
          lotsArray[index].is_used = true
        }
        if (item.floor === floorId) {
          lotsArray[index].is_selected = true
        }
      }

      else {
        if (item.id === floorId) {
          lotsArray[index].is_used = false
        }
        if (item.floor === floorId) {
          lotsArray[index].is_selected = false
        }
      }
    })
    setLotsToAdd([...lotsArray])
    // filter by floor then 'setIsSelected=true" to all item= that match
  }

  const onClickLot = (lotId) => {
    let lotsArray = lotsToAdd
    const selectLots = lotsToAdd.map((item, index) => {
      if (item.id === lotId) {
        if (item.is_selected) {
          lotsArray[index].is_selected = false
        }
        else {
          lotsArray[index].is_selected = true
        }
      }
    })
    setLotsToAdd([...lotsArray])
    // filter by lotId then 'setIsSelected=true" to all item= that match
  }

  return (
    <div className={classes.lotGroupCard}>
      <div className={classes.infoContainer}>
        <div className={classes.infoHeader}>
          <div className={classes.infoActions}>
            <Typography variant="body1">{display}</Typography>
            <div className={'flex'}>
              <IconButton
                aria-label={dictionary["edit"]}
                onClick={() => handleUpdateOpen(groupId)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label={dictionary["delete"]}
                onClick={() => handleDeleteOpen(groupId)}
              >
                <DeleteIcon />
              </IconButton>
            </div>

          </div>

          <Typography
            paragraph
            className={classes.noMargin}
            sx={{ color: 'text.disabled' }}
          >
            {lotGroupLots.length} {dictionary['lots']} ({numberWithSpacesNoDecimalAdded(surface)} m2)
          </Typography>
        </div>

        {!isLoadingLot && !isChange && !isLoadingGroup && !isEqual(lotGroupLots.sort(compareNumber), filteredLot.sort(compareNumber)) &&
          <div >
            <Button
              variant="contained"
              color="primary"
              size='small'
              disabled={isLoading}
              onClick={onSubmit}
            >
              {dictionary['submit']}
            </Button>
          </div>
        }
      </div>
      {isLoadingLot || isLoadingGroup ?
        <div className={classes.loading}><CircularProgress /></div>
        :
        <>
          <Scrollbars>
            <div className={classes.lotContainer}>
              {lotsToAdd &&
                lotsToAdd.length > 0 &&
                lotsToAdd.map((lot) => {
                  if (lot.is_building) {
                    return <div className={classes.building} onClick={() => onClickBuilding(lot.id, lot.is_used)} >{lot.name}</div>
                  }
                  if (lot.is_floor) {
                    return <div className={classes.floor} onClick={() => onClickFloor(lot.id, lot.is_used)} >{lot.name}</div>
                  }
                  if (!lot.floor && lot.building) {
                    return <div className={clsx(classes.lotBuilding, lot.is_selected && classes.lotSelected)} onClick={() => onClickLot(lot.id)}>{lot.name}</div>
                  }
                  if (!lot.floor && !lot.building) {
                    return <div className={clsx(classes.lotAlone, lot.is_selected && classes.lotSelected)} onClick={() => onClickLot(lot.id)} >{lot.name}</div>
                  } else {
                    return (
                      <div className={clsx(classes.lotBuildingFloor, lot.is_selected && classes.lotSelected)} onClick={() => onClickLot(lot.id)}>{lot.name}</div>
                    )
                  }
                })}
            </div>
          </Scrollbars>
          {!isChange && !isEqual(lotGroupLots.sort(compareNumber), filteredLot.sort(compareNumber)) &&
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                size='small'
                disabled={isLoading}
                onClick={onSubmit}
              >
                {dictionary['submit']}
              </Button>
            </div>
          }
        </>
      }
      {isDelete &&
        <ModalDelete
          onClose={handleDeleteClose}
          onDelete={onDelete}
        />
      }
      {isEdit &&
        <ModalLotGroup
          operationId={operationId}
          idGroup={groupId}
          isEdit
          onClose={() => handleUpdateClose()}
        />
      }
    </div>
  )
}
