import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import uniqBy from 'lodash/uniqBy'
import { useFetchData } from '@api/fetchData'
import CircularProgress from '@material-ui/core/CircularProgress'

import { LotGroupCard } from '.'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginRight: theme.spacing(4),
  },
  submit: {
    marginRight: theme.spacing(4),
  },
  noMargin: {
    marginBottom: 0,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
    height: 200
  },
  lotGroupContainer: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    maxHeight: 1040,
  },
  lotGroupInfo: {
    display: 'flex',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: 'column',
    boxShadow: theme.shadows[25].z16,
    borderRadius: theme.shape.borderRadiusMd,
    width: '100%',
    maxWidth: '200px',
  },
}))

export default function LotGroupCategoriesView({ id }) {
  const classes = useStyles()

  const fetchUrl = `/lots/?operation=${id}&is_private=true&page_size=999`
  const { data, isLoading, isError } = useFetchData(fetchUrl, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const fetchUrlGroup = `/lots/groups/?operation=${id}`
  const {
    data: lotGroupData,
    isLoading: isLoadingLotGroup,
    isError: isErrorLotGroup,
  } = useFetchData(fetchUrlGroup, null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const orderByBuildingAndFloor = (data) => {
    const extractBuilding = data
      .filter((lot) => lot.building)
      .map((lot) => ({ id: lot.building, name: lot.building_display }))
    const removeDuplicateBuilding = uniqBy(extractBuilding, 'id')
    let organizedArray = []
    const extractFloorWithBuilding = removeDuplicateBuilding.flatMap(
      (building) => {
        organizedArray.push({ ...building, is_building: true })
        organizedArray.push(
          data
            .filter((lot) => lot.building === building.id && lot.floor)
            .map((lot) => ({
              id: lot.floor,
              building: lot.building,
              name: lot.floor_display,
              is_floor: true,
            })),
        )
      },
    )

    const floorAndBuilding = organizedArray.flat()
    const removeDuplicateFloor = uniqBy(floorAndBuilding, 'id')
    let lotFilterdByFloorAndBuilding = []

    const addLot = removeDuplicateFloor.map((item) => {
      lotFilterdByFloorAndBuilding.push({ ...item })
      if (item.is_building) {
        let filteredByBuilding = data.filter(
          (lot) => lot.building === item.id && !lot.floor,
        )
        filteredByBuilding.map((lot) =>
          lotFilterdByFloorAndBuilding.push({ ...lot }),
        )
      }
      if (item.is_floor) {
        let filteredByFloor = data.filter((lot) => lot.floor === item.id)
        filteredByFloor.map((lot) =>
          lotFilterdByFloorAndBuilding.push({ ...lot }),
        )
      }
    })

    const lotWithNoBuilding = data.filter((lot) => !lot.building)
    const addLotNoBuilding = lotWithNoBuilding.map((lot) => {
      lotFilterdByFloorAndBuilding.push({ ...lot })
    })

    return lotFilterdByFloorAndBuilding
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        {isLoadingLotGroup ?
          <div className={classes.loading}><CircularProgress /></div>
          :

          <div className={classes.lotGroupContainer}>
            {lotGroupData &&
              lotGroupData.results &&
              lotGroupData.results.map((lotGroup, index) => {
                return (
                  <LotGroupCard
                    key={lotGroup.id}
                    lots={
                      data &&
                      data.results &&
                      orderByBuildingAndFloor(data.results)
                    }
                    groupId={lotGroup.id}
                    display={lotGroup.display}
                    surface={lotGroup.surface}
                    lotGroupLots={lotGroup.lots}
                    isLoadingLot={isLoading}
                    fetchUrl={fetchUrl}
                    operationId={id}
                    fetchUrlGroup={fetchUrlGroup}
                    isLoadingGroup={isLoadingLotGroup}
                  />
                )
              })}
          </div>
        }
      </Paper>
    </Grid>
  )
}
