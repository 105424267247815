import React, { useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'
import { useRouter } from "next/router";

import { Copyright, Layout, StickyHeader, ModalLotGroup } from '@components/common'
import { LotGroupCategoriesView, LotGroupLots } from './index'
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import useBreakpoints from '@hooks/useBreakpoints'
import { useOperation } from "@contexts/operation"
import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    marginRight: theme.spacing(4),
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
  line: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  }
}))


export default function LotGroupsView({ operationId, isCategory }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { operation } = useOperation()
  const router = useRouter();
  const [isOpenForm, setIsOpenForm] = useState(false)
  const isMobile = useBreakpoints('down', 'sm');

  return (
    <Layout isSidebar operationId={operationId}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <StickyHeader
            operationName={operation?.display}
            breadcrumb={[
              { name: dictionary["operations"], href: `/operations/` },
              { name: operation?.name, href: `/operations/${operationId}` },
              { name: dictionary["budgets"], href: `/operations/${operationId}/budgets` },
              { name: dictionary["lot_groups"] }
            ]} />
          <Grid item xs={12}>
            <div className={classes.headerContainer}>
              <Button
                onClick={() => router.push(`/operations/${operationId}/budgets`)}
              >
                {dictionary['back_budget']}
              </Button>
              <div className={classes.actionsContainer}>
                <Button
                  onClick={() => isCategory ? router.push(`/operations/${operationId}/budgets/groups`) : router.push(`/operations/${operationId}/budgets/categories`)}
                  className={classes.link}
                >
                  {isCategory ? dictionary['manage_lot_groups'] : dictionary['manage_category_mapping']}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  className={classes.submit}
                  onClick={() => setIsOpenForm(true)}
                >
                  {!isMobile && dictionary['add_group']}
                </Button>
              </div>

            </div>
          </Grid>
          {isCategory ?
            <LotGroupCategoriesView id={operationId} />
            :
            <LotGroupLots id={operationId} />
          }
        </Grid>
        {isOpenForm &&
          <ModalLotGroup
            operationId={operationId}
            onClose={() => setIsOpenForm(false)}
          />
        }
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
