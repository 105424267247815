import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { useGridSlotComponentProps } from '@material-ui/data-grid'
import Pagination from '@material-ui/lab/Pagination'

import { useTranslation } from '@contexts/translation'
import { numberWithSpacesNoDecimalAdded } from '@utils/numberWithSpaces'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function FooterRow({ loading }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { state, apiRef, columns, rows } = useGridSlotComponentProps()
  const totalRow = () => {
    if (rows?.length > 0) {
      const forceReduce = rows.push({
        name: '',
        category: null,
        budgeted_amount: 0,
        actual_amount: 0,
      })
      const totalRow = rows?.reduce((acc, value) => {
        return {
          name: dictionary['total'],
          category: null,
          budgeted_amount: acc.budgeted_amount + value.budgeted_amount,
          actual_amount: acc.actual_amount + value.actual_amount,
        }
      })
      return [totalRow]
    }
  }


  const itemStyleCategoryGroup = {
    width: columns[0].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }
  const itemStyleCategory = {
    width: columns[1].computedWidth,
    padding: '0 18px',
    fontWeight: 'bold',
    textAlign: 'right',
  }
  const itemStyleBudget = {
    width: columns[2].computedWidth,
    padding: '0 10px',
    fontWeight: 'bold',
    textAlign: 'right',
  }
  const itemStyleActual = {
    width: columns[3].computedWidth,
    padding: '0 18px',
    fontWeight: 'bold',
    textAlign: 'right',
  }

  return (
    <div className={`${classes.root} MuiDataGrid-footer`}>
      {rows?.length > 0 && columns.length > 3 && !state.options.loading && !loading && (
        <div>
          {totalRow().map((item, index) => (
            <div className={classes.list} key={`${item?.name}-${index}`}>
              <div style={itemStyleCategoryGroup}>
                {state.pagination.pageCount > 1 && (
                  <Pagination
                    color="primary"
                    count={state.pagination.pageCount}
                    page={state.pagination.page + 1}
                    size="small"
                    siblingCount={0}
                    onChange={(event, value) => value && apiRef.current.setPage(value - 1)} />
                )}
              </div>
              <div style={itemStyleCategory}>{item?.category}</div>
              <div style={itemStyleBudget}>
                {numberWithSpacesNoDecimalAdded(item?.budgeted_amount)}
              </div>
              <div style={itemStyleActual}>
                {numberWithSpacesNoDecimalAdded(item?.actual_amount)}
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  )
}
