import client from '@utils/client'

// Categories group
export const sendAddCategoryGroup = (payload) => {
  return client
    .post('/categories/group/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const updateCategoryGroup = (payload, id) => {
  return client
    .patch(`/categories/group/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteCategoryGroup = (id) => {
  return client
    .delete(`/categories/group/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

// Categories
export const sendAddCategory = (payload) => {
  return client
    .post('/categories/', {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const updateCategory = (payload, id) => {
  return client
    .patch(`/categories/${id}/`, {
      ...payload,
    })
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}

export const deleteCategory = (id) => {
  return client
    .delete(`/categories/${id}/`)
    .then((response) => response)
    .catch((error) => {
      return error.response
    })
}