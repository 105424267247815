import React, { useState } from 'react'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'

import { BudgetsFormGroup, BudgetsFormCategory } from '@components/budgets'
import { ModalDelete, ModalForm, Table, ActionTable, CellExpand } from '@components/common'

import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { Tab } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import { deleteCategoryGroup, deleteCategory } from '@api/categories'
import useBreakpoints from '@hooks/useBreakpoints'

import { useFetchData } from '@api/fetchData'

import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  containerTable: {
    width: '100%',
  },
  tableNav: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },

  gridContainer: {
    border: 'none',
  },
  modalContainer: {
    padding: theme.spacing(8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDelete: {
    height: 'fit-content',
    padding: theme.spacing(4),
  },
  buttonDelete: {
    marginRight: theme.spacing(8),
  },
  tabList: {
    minHeight: 36,
  },
  tab: {
    minHeight: 36,
  },
  submit: {
    '@media (max-width: 600px)': {
      minWidth: 'initial',
      padding: theme.spacing(1),
      '& span': {
        marginRight: '0',
        marginLeft: '0',
      },
    },
  },
}))

export default function BudgetsTableCategories({ operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState('group')
  const [pageCount, setPageCount] = useState(1)
  const isMobile = useBreakpoints('down', 'sm')

  const fetchUrlGroup = `/categories/group/?operation=${operationId}&page=${pageCount}`
  const fetchUrlCat = `/categories/?operation=${operationId}&page=${pageCount}`

  const {
    data: categories,
    isLoading: isLoadingCategories,
    isError: isErrorCategories,
  } = useFetchData(fetchUrlCat)
  const {
    data: categoriesGroup,
    isLoading: isLoadingGroup,
    isError: isErrorGroup,
  } = useFetchData(fetchUrlGroup)

  const handleChange = (event, newValue) => setValue(newValue)

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [idRow, setIdRow] = useState(null)


  const handleFormOpen = () => {
    setIsOpenForm(true)
  }
  const handleFormClose = () => {
    setIsOpenForm(false)
  }

  const handleEditOpen = () => {
    setIsEdit(true)
  }
  const handleEditClose = () => {
    setIsEdit(false)
  }

  const handleDeleteOpen = () => {
    setIsDelete(true)
  }

  const handleDeleteClose = () => {
    setIsDelete(false)
  }

  const onDelete = async () => {
    const response =
      value === 'group'
        ? await deleteCategoryGroup(idRow)
        : await deleteCategory(idRow)

    if (response) {
      if (response.status === 204) {
        mutate(value === 'group' ? fetchUrlGroup : fetchUrlCat)
        enqueueSnackbar(
          `${value === 'group'
            ? `${dictionary['category_class']} `
            : `${dictionary['category']} `
          } ${dictionary['deleted']}`,
          {
            variant: 'success',
          },
        )
        handleDeleteClose()
      }
    }
  }

  const actions = (id) => [
    {
      name: dictionary['edit'],
      onClick: () => {
        handleEditOpen()
        setIdRow(id)
      },
    },
    {
      name: dictionary['delete'],
      onClick: () => {
        handleDeleteOpen()
        setIdRow(id)
      },
    },
  ]

  const columnsGroup = [
    {
      field: 'name', headerName: dictionary['class'],
      minWidth: 160,
      flex: 1,
      renderCell: CellExpand
    },
    {
      field: 'is_recuperable',
      headerName: dictionary['recuperable'],
      type: 'boolean',
      minWidth: 240,
      renderCell: (param) =>
        param.row.is_recuperable ? (
          <CheckIcon />
        ) : (
          <ClearIcon />
        ),
    },
    {
      field: 'is_invoiced_directly',
      headerName: dictionary['invoiced_directly'],
      type: 'boolean',
      minWidth: 240,
      renderCell: (param) =>
        param.row.is_invoiced_directly ? <CheckIcon /> : <ClearIcon />,
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]

  const columnsCategories = [
    {
      field: 'name', headerName: dictionary['category'],
      minWidth: 160,
      flex: 1,
      renderCell: CellExpand
    },
    {
      field: 'cat_group_display',
      headerName: dictionary['class'],
      minWidth: 160,
      flex: 1,
      renderCell: CellExpand
    },
    {
      field: 'lot_group',
      headerName: dictionary['lot_group'],
      minWidth: 160,
      valueGetter: ({ row }) => row?.lot_group?.display
    },
    {
      field: 'action',
      headerName: dictionary['actions'],
      cellClassName: 'actions_cell',
      width: 24,
      sortable: false,
      renderCell: (params) => <ActionTable actions={actions(params.row.id)} />,
    },
  ]

  const TAB = [
    { value: 'group', label: dictionary['category_class'], disabled: false },
    { value: '', label: dictionary['categories'], disabled: false },
  ]

  return (
    <div className={classes.containerTable}>
      <TabContext value={value}>
        <div className={classes.tableNav}>
          <TabList className={classes.tabList} onChange={handleChange}>
            {TAB.map((tab) => (
              <Tab key={tab.value} className={classes.tab} label={tab.label} value={tab.value} />
            ))}
          </TabList>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            className={classes.submit}
            onClick={handleFormOpen}
            type="button"
          >
            {!isMobile ?
              value === 'group'
                ? dictionary['add_class']
                : dictionary['add_category']
              : null}
          </Button>
        </div>
      </TabContext>
      <Table
        className={classes.gridContainer}
        rows={
          value === 'group'
            ? categoriesGroup && categoriesGroup.results
              ? categoriesGroup.results
              : [{ id: 0 }]
            : categories && categories.results
              ? categories.results
              : [{ id: 0 }]
        }
        columns={value === 'group' ? columnsGroup : columnsCategories}
        isLoading={isLoadingCategories || isLoadingGroup}
        page={pageCount}
        handlePageChange={handlePageChange}
        count={
          value === 'group'
            ? categoriesGroup && categoriesGroup.count
            : categories && categories.count
        }
      />
      {(isOpenForm || isEdit) && value === 'group' && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary['class']}
          onClose={isEdit ? handleEditClose : handleFormClose}
        >
          <BudgetsFormGroup
            handleClose={isEdit ? handleEditClose : handleFormClose}
            isEdit={isEdit}
            operationId={operationId}
            fetchUrl={fetchUrlGroup}
            fetchCat={fetchUrlCat}
            categories={categories && categories.results}
            id={idRow}
          />
        </ModalForm>
      )}

      {(isOpenForm || isEdit) && value === '' && (
        <ModalForm
          className={classes.modalContainer}
          isEdit={isEdit}
          type={dictionary['category']}
          onClose={isEdit ? handleEditClose : handleFormClose}
        >
          <BudgetsFormCategory
            handleClose={isEdit ? handleEditClose : handleFormClose}
            isEdit={isEdit}
            fetchUrl={fetchUrlCat}
            operationId={operationId}
            categoriesGroup={categoriesGroup && categoriesGroup.results}
            id={idRow}
          />
        </ModalForm>
      )}

      {isDelete && (
        <ModalDelete onClose={handleDeleteClose} onDelete={onDelete} />
      )}
    </div>
  )
}
