import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { updateBudgets } from '@api/budgets'
import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function BudgetsForm({ id, handleClose, operationId, categoryId, isEdit, year, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
    shouldUnregister: false,
  })

  const { data, isLoading, isError } = isEdit
    ? useFetchData(`/budgets/year/${id}/?&year=${year}`)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('budgeted_amount', data?.budgeted_amount)
    }
  }, [data, isEdit]);


  const onSubmitEdit = async (data) => {
    setLoading(true)
    data['operation'] = Number(operationId)
    data['category'] = Number(categoryId)
    let response = await updateBudgets(data, id)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["budget_edited"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmitEdit)}
    >
      <Grid container>

        {/* Budgets  */}
        <Grid xs="12">
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="budgeted_amount"
            label={dictionary["budget"]}
            name="budgeted_amount"
            type='number'
            inputRef={register}
            error={errors.budgeted_amount}
            helperText={errors.budgeted_amount && errors.budgeted_amount.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid xs="12" className={classes.buttonContainer}>

          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>

      </Grid>
    </form>
  )
}
