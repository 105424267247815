import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { mutate } from 'swr'
import { useFetchData } from '@api/fetchData'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useSnackbar } from 'notistack';
import { useTranslation } from '@contexts/translation'
import { Typography, Card } from '@material-ui/core'
import { updateCategory } from '@api/categories'
import { numberWithSpacesNoDecimalAdded } from '@utils/numberWithSpaces'
import CircularProgress from '@material-ui/core/CircularProgress'


const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
  },
  actions: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  containerTitle: {
    display: "flex",
    flexDirection: "row"
  },
  title: {
    marginRight: theme.spacing(4)
  },
  submit: {
    marginRight: theme.spacing(4)
  },
  noMargin: {
    marginBottom: 0
  },
  droppableZone: {
    height: '100%',
    minHeight: 150
  },
  notAssignedContainer: {
    width: '20%',
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    minWidth: '180px',
    maxHeight: '300px'
  },
  lotGroupContainer: {
    display: 'flex',
    flexDirection: "row",
    width: '80%',
    overflowX: 'auto',
  },
  lotGroupCardHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  infoActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lotGroupCard: {
    display: "flex",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: "column",
    boxShadow: theme.shadows[25].z16,
    borderRadius: theme.shape.borderRadiusMd,
    width: '100%',
    minWidth: '260px'
  },
  notAssignedCard: {
    display: "flex",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    flexDirection: "column",
    boxShadow: theme.shadows[25].z16,
    borderRadius: theme.shape.borderRadiusMd,
    width: '100%'
  },
  categoryDroppableCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(3),
    height: 200
  },
  flex: { display: 'flex' },
}))


export default function LotGroupCategoriesView({ id }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar();
  const [tempData, setTempData] = useState(null)

  const fetchUrl = `/categories/?operation=${id}&page_size=999`
  const fetchUrlGroup = `/lots/groups/?operation=${id}`
  const { data, isLoading, isError } = useFetchData(fetchUrl)
  const { data: lotGroupData, isLoading: isLoadingLotGroup, isError: isErrorLotGroup } = useFetchData(fetchUrlGroup)


  useEffect(() => {
    if (data && data.results) {
      setTempData(data.results)
      const categoryNoGroup = data.results.filter((category) => !category.lot_group)
    }
  }, [data])



  const onDragEnd = async result => {
    const { source, destination, draggableId } = result

    // dropped outside the list
    if (!destination) {
      return;
    }
    // dropped in the same list
    if (source.droppableId === destination.droppableId) {
      return;
    }
    // dropped to not assigned 
    if (destination.droppableId === "no_lot") {
      return;
    }
    else {
      let data = {}
      data['lot_group'] = destination.droppableId
      tempData.map((item, index) => {
        if (item.id == draggableId) {

          let newData = [...tempData]
          if (newData[index].lot_group) {
            newData[index].lot_group.id = parseInt(destination.droppableId)
          }
          else {
            newData[index].lot_group = { id: parseInt(destination.droppableId) }
          }
          setTempData(newData)
        }
      })
      let response = await updateCategory(data, draggableId)
      if (response) {
        if (response.status === 200)
          enqueueSnackbar(dictionary['lot_group_updated'], {
            variant: 'success',
          })
        mutate(fetchUrl)
        mutate(fetchUrlGroup)

      }
    }
  };


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {isLoadingLotGroup ? <div className={classes.loading}><CircularProgress /></div>
            : <>
              <div className={classes.notAssignedContainer}>
                <div className={classes.notAssignedCard}>
                  <div className={classes.lotGroupCardHeader}>
                    <Typography variant="body1">
                      {dictionary['not_assigned']}
                    </Typography>
                  </div>
                  <Droppable droppableId="no_lot" >
                    {(provided, snapshot) => (
                      <div className={classes.droppableZone} ref={provided.innerRef}>
                        {tempData && tempData.map((category, index) => {
                          if (!category.lot_group) {
                            return (
                              <Draggable
                                key={category.id}
                                draggableId={category.id.toString()}
                                index={index}>
                                {(provided, snapshot) => (
                                  <Card
                                    className={classes.categoryDroppableCard}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {category.name}
                                  </Card>
                                )}
                              </Draggable>)
                          }
                        }
                        )
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
              <div className={classes.lotGroupContainer}>
                {lotGroupData && lotGroupData.results &&
                  lotGroupData.results.map((lotGroup, index) => {
                    return (
                      <div
                        className={classes.lotGroupCard}
                      >
                        <div className={classes.lotGroupCardHeader}>
                          <div className={classes.infoActions}>
                            <Typography variant="body1">
                              {lotGroup.display}
                            </Typography>
                          </div>

                          <Typography
                            paragraph
                            className={classes.noMargin}
                            sx={{ color: 'text.disabled' }}
                          >
                            {lotGroup.lots.length} {dictionary['lots']}
                          </Typography>
                          <Typography
                            paragraph
                            className={classes.noMargin}
                            sx={{ color: 'text.disabled' }}
                          >
                            {numberWithSpacesNoDecimalAdded(lotGroup.surface)} m2
                          </Typography>
                        </div>
                        <Droppable droppableId={lotGroup.id.toString()} >
                          {(provided, snapshot) => (
                            <div className={classes.droppableZone} ref={provided.innerRef}>
                              {tempData && tempData.map((category, index) => {
                                if (category.lot_group && category.lot_group.id === lotGroup.id) {
                                  return (
                                    <Draggable
                                      key={category.id}
                                      draggableId={category.id.toString()}
                                      index={index}>
                                      {(provided, snapshot) => (
                                        <Card
                                          className={classes.categoryDroppableCard}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          {category.name}
                                        </Card>
                                      )}
                                    </Draggable>)
                                }
                              }
                              )
                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )
                  }
                  )
                }
              </div>
            </>}
        </Paper>
      </Grid>
    </DragDropContext >
  )
}
