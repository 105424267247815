import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { sendAddCategoryGroup, updateCategoryGroup } from '@api/categories'
import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import MenuItem from '@material-ui/core/MenuItem'

import { Select, Switch } from "@components/form"

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function BudgetsFormGroup({ id, handleClose, operationId, categories, isEdit, fetchUrl, fetchCat }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur',
  })


  // get all categories for the select all 
  const categoriesId = () => {
    let categoryArray = []
    categories.length > 1 && categories.map(category => {
      categoryArray.push(category.id)
      return categoryArray
    })
    return categoryArray
  }

  const categoriesIdArray = categoriesId()

  const { data, isLoading, isError } = isEdit
    ? useFetchData('/categories/group/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }




  const getCategories = (categories) => categories.filter(category => category.id).map(category => category.id)


  useEffect(() => {
    if (isEdit && data) {
      setValue('name', data?.name)
      setValue('is_invoiced_directly', data?.is_invoiced_directly)
      setValue('is_recuperable', data?.is_recuperable)
      setValue('categories', data?.categories && getCategories(data.categories))
    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)
    data['operation'] = Number(operationId)
    let response = await sendAddCategoryGroup(data)
    if (response) {
      if (response.status === 201) {
        handleClose()
        enqueueSnackbar(dictionary["class_created"], {
          variant: "success"
        })
        mutate(fetchUrl)
        mutate(fetchCat)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    data['operation'] = Number(operationId)
    let response = await updateCategoryGroup(data, id)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["class_edited"], {
          variant: "success"
        })
        mutate(fetchCat)
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>
        {/* Name */}
        <Grid xs="12">
          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="name"
            label={dictionary["name"]}
            name="name"
            inputRef={register}
            error={errors.name}
            helperText={errors.name && errors.name.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {/* Is recuperable */}
        <Grid xs="12">
          <Switch
            margin="normal"
            id="is_recuperable"
            label={dictionary["is_recuperable"]}
            name="is_recuperable"
            control={control}
            error={errors.is_recuperable}
            helperText={errors.is_recuperable && errors.is_recuperable.message}
            labelPlacement="end"
            switchColor="primary"
          />
        </Grid>
        {/* Is invoiced directly */}
        <Grid xs="12">
          <Switch
            margin="normal"
            id="is_invoiced_directly"
            label={dictionary["is_invoiced_directly"]}
            name="is_invoiced_directly"
            control={control}
            error={errors.is_invoiced_directly}
            helperText={errors.is_invoiced_directly && errors.is_invoiced_directly.message}
            labelPlacement="end"
            switchColor="primary"
          />
        </Grid>
        {/* Categories */}
        <Grid xs="12">
          <Select
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="categories"
            label={dictionary["categories"]}
            name="categories"
            inputRef={register}
            error={errors.categories}
            control={control}
            helperText={errors.categories && errors.categories.message}
            multiple={true}
            arrayValue={categoriesIdArray}
            defaultValue={[]}
            MenuProps={{
              getContentAnchorEl: () => null,
            }}
          >
            <MenuItem key={'all'} value='all' >{dictionary['select_all']}</MenuItem>
            {categories?.map(category => (
              <MenuItem key={category?.id} value={category?.id}>{category?.name}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form >
  )
}
