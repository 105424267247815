import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Page404View from 'pages/404'

import uniqBy from 'lodash/uniqBy'
import useBreakpoints from '@hooks/useBreakpoints'

import { useTheme, makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Tab, Menu, MenuItem } from '@material-ui/core'
import { TabContext, TabList } from '@material-ui/lab'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { Layout, Table, StickyHeader, Copyright, CellExpand } from '@components/common'
import FooterRow from './FooterRow'
import { useRouter } from "next/router";

import { useFetchData } from '@api/fetchData'
import { mutate } from 'swr'
import { updateBudgets } from '@api/budgets'

import { useSnackbar } from 'notistack'

import { useOperation } from '@contexts/operation'
import { useTranslation } from '@contexts/translation'

import { MLabel } from '@theme/@material-extend'

import { numberWithSpacesNoDecimalAdded } from '@utils/numberWithSpaces'

import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxHeight: '100vh',
  },
  container: {
    width: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  tabList: {
    minHeight: 36,
  },
  tab: {
    minHeight: 36,
  },
  number: {
    width: '100%',
    textAlign: 'right',
  },
  selectYear: {
    width: 100,
  },
  filter: {
    '& div': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px',
    },
  },
  actions: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  actionMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  containerTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    marginRight: theme.spacing(4),
  },
  submit: {
    marginRight: theme.spacing(4),
  },
  link: {
    marginBottom: 0,
  },
  sticky: {
    position: 'sticky',
    zIndex: '999',
    top: '40px',
  },
}))

export default function BudgetsView({ id }) {
  const currentYear = new Date().getFullYear()
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useBreakpoints('down', 'sm')


  const [value, setValue] = useState('all')
  const [filterId, setFilterId] = useState()
  const router = useRouter();

  const { operation } = useOperation()
  const [year, setYear] = useState(currentYear)
  const [pastYears, setPastYears] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const fetchUrl = `/budgets/year/?year=${year}&operation=${id}&page=${pageCount}`
  const { data, isLoading, isError } = useFetchData(fetchUrl)
  const [anchorElManage, setAnchorElManage] = useState(null)

  const handleOpenMenu = (event) => {
    setAnchorElManage(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElManage(null);
  };

  const {
    data: dataYears,
    isLoading: isLoadingYears,
    isError: isErrorYears,
  } = useFetchData('/budgets/year/')

  const handlePageChange = (params) => {
    setPageCount(params + 1)
  }

  const getCategories = (data) => {
    if (data) {
      const getCategoriesGroupData = data
        .map((budget) => [
          {
            id: budget.category.cat_group && budget.category.cat_group.id,
            name: budget.category.cat_group && budget.category.cat_group.name,
          },
        ])
        ?.flat()

      return getCategoriesGroupData
    }
  }

  const categoriesGroupList = getCategories(data && data.results)
  const filteredCategoriesGroupList = uniqBy(categoriesGroupList, 'id')

  const filteredByCategoryGroup = (id) =>
    data.results.length > 1 &&
    data.results.filter((data) => {
      return data.category.cat_group && data.category.cat_group.id === id
    })

  const handleChange = (event, newValue) => {
    setValue(newValue)
    setPageCount(1)
    if (newValue === 'all') {
      return setFilterId(null)
    } else {
      return setFilterId(newValue)
    }
  }

  useEffect(() => {
    if (!isLoadingYears) {
      const tempYears = dataYears?.results
        ?.map((result) => result.year)
        ?.filter((y) => y !== currentYear && y !== currentYear + 1)
      setPastYears(Array.from(new Set(tempYears)))
    }
  }, [isLoadingYears])

  const columns = [
    {
      field: 'group',
      headerName: dictionary['category_group'],
      minWidth: 260,
      valueGetter: ({ row }) => row?.category?.cat_group?.name,
      renderCell: CellExpand,
    },
    {
      field: 'category',
      headerName: dictionary['category'],
      minWidth: 260,
      flex: 1,
      valueGetter: ({ row }) => row?.category?.name,
      renderCell: CellExpand,
    },
    {
      field: 'budgeted_amount',
      headerName: dictionary['budget'],
      type: 'number',
      minWidth: 260,
      editable: true,
    },
    {
      field: 'actual_amount',
      headerName: dictionary['actual'],
      type: 'number',
      minWidth: 260,
      headerClassName: 'round_corner',
      renderCell: (params) => (
        <div className={classes.number}>
          {params.row.actual_amount >= 0 && (
            <MLabel
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={params.row.over_budget ? 'error' : 'info'}
            >
              {params.row.actual_amount &&
                numberWithSpacesNoDecimalAdded(params.row.actual_amount)}
            </MLabel>
          )}
        </div>
      ),
    },
  ]

  const onEditBudget = async ({ id, field, value }, event) => {
    if (
      typeof value === 'string' &&
      (value.includes('e') ||
        value.includes('+') ||
        value.includes('-') ||
        value === '')
    ) {
      event.stopPropagation()
      enqueueSnackbar('Please enter a correct value', {
        variant: 'error',
      })
      mutate(fetchUrl)
      return
    } else {
      const newBudget = { budgeted_amount: value }
      setLoading(true)
      let response = await updateBudgets(newBudget, id)
      if (response) {
        if (response.status === 200) {
          setLoading(false)
          enqueueSnackbar(dictionary['budget_edited'], {
            variant: 'success',
          })
          mutate(fetchUrl)
        }
        if (response.status === 400) {
          setLoading(false)
          mutate(fetchUrl)
          enqueueSnackbar(dictionary['budget_edited'], {
            variant: 'error',
          })
        }
      }
    }
  }

  if (typeof window !== 'undefined') {
    document.querySelector('.MuiDataGrid-cellEditable') &&
      document
        .querySelector('.MuiDataGrid-cellEditable')
        .addEventListener('keypress', function (evt) {
          if (
            (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
            evt.which > 57
          ) {
            evt.preventDefault()
          }
        })
  }

  if (isError) return <Page404View />


  return (
    <Layout isSidebar operationId={id}>
      <TabContext value={value}>
        <main className={classes.content}>
          <Container maxWidth={false} className={classes.container}>
            <Grid container spacing={3}>
              <StickyHeader
                operationName={operation?.display}
                breadcrumb={[
                  { name: dictionary['operations'], href: `/operations/` },
                  { name: operation?.name, href: `/operations/${id}` },
                  { name: dictionary['budgets'] },
                ]}
              />
              <Grid item xs={12}>
                <Paper className={classes.actions}>
                  <div className={isMobile ? classes.actionMobile : classes.actionContainer}>

                    <Button
                      onClick={handleOpenMenu}
                      className={classes.submit}
                    >
                      {dictionary['actions']}
                    </Button>

                    <Menu
                      anchorEl={anchorElManage}
                      open={Boolean(anchorElManage)}
                      onClose={handleCloseMenu}
                      elevation={0}
                      getContentAnchorEl={null}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      {/*  Little hack with next/router to pass props in url and hide it */}
                      <MenuItem onClick={() => router.push({ pathname: `/operations/${id}/settings`, query: { info: 4 } }, `/operations/${id}/settings`)
                      } >
                        {dictionary['manage_categories']}
                      </MenuItem>

                      <MenuItem onClick={() => {
                        router.push(`/operations/${id}/budgets/groups`)
                      }}>
                        {dictionary['manage_lot_groups']}
                      </MenuItem>

                      <MenuItem onClick={() => {
                        router.push(`/operations/${id}/budgets/categories`)
                      }}>
                        {dictionary['manage_category_mapping']}
                      </MenuItem>

                    </Menu>
                    <FormControl
                      variant="outlined"
                      className={classes.selectYear}
                    >
                      <InputLabel id="select-year-label">
                        {dictionary['year']}
                      </InputLabel>
                      <Select
                        labelId="select-year-label"
                        id="select-year"
                        value={year}
                        isLoading={isLoadingYears}
                        className={classes.filter}
                        onChange={(e) => setYear(e.target.value)}
                        label={dictionary['year']}
                      >
                        {pastYears && pastYears?.map((pastYear) => (
                          <MenuItem value={pastYear}>{pastYear}</MenuItem>
                        ))}
                        <MenuItem value={currentYear}>{currentYear}</MenuItem>
                        <MenuItem value={currentYear + 1}>
                          {currentYear + 1}
                        </MenuItem>
                      </Select>
                    </FormControl>

                  </div>

                  <TabList
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    className={classes.tabList}
                    onChange={handleChange}
                  >
                    <Tab key={'all'} label={dictionary['all']} value={'all'} />
                    {filteredCategoriesGroupList.length > 0 &&
                      filteredCategoriesGroupList.map((tab) => (
                        <Tab className={classes.tab} key={tab.id} label={tab.name} value={tab.id} />
                      ))}
                  </TabList>
                </Paper>
                <Paper className={classes.paper}>
                  <Table
                    className={classes.gridContainer}
                    rows={
                      data && data.results
                        ? filterId
                          ? filteredByCategoryGroup(filterId)
                          : data.results
                        : [{ id: 0, category: { name: '' } }]
                    }
                    columns={columns}
                    page={pageCount}
                    pageSize={
                      data && data.results
                        ? filterId
                          ? filteredByCategoryGroup(filterId).length
                          : data.results.length
                        : 0
                    }
                    handlePageChange={handlePageChange}
                    count={
                      data && data.count
                        ? filterId
                          ? filteredByCategoryGroup(filterId).length
                          : data.count
                        : 0
                    }
                    loading={isLoading || loading}
                    onCellEditCommit={onEditBudget}
                    components={{
                      Footer: () => <FooterRow loading={loading} />,
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>

            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </TabContext>
    </Layout>
  )
}
