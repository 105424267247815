import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack';

import { sendAddCategory, updateCategory } from '@api/categories'
import { useFetchData } from '@api/fetchData'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import MenuItem from '@material-ui/core/MenuItem'

import { Select } from "@components/form"

import { useTranslation } from '@contexts/translation'
import { Grid } from '@material-ui/core';
import { getLotGroups } from "@api/lots";

const useStyles = makeStyles((theme) => ({
  form: {
    padding: theme.spacing(2),
  },
  formRadioGroupContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: theme.spacing(4),
  },
  formCheckbox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
  },
}))

export default function BudgetsFormCategory({ id, isEdit, handleClose, operationId, categoriesGroup, fetchUrl }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [lotGroups, setLotGroups] = useState([])
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    getLotGroups({ operation: operationId })
      .then(res => {
        setLotGroups(res?.data?.results || [])
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const {
    register,
    handleSubmit,
    setError,
    errors,
    setValue,
    control
  } = useForm({
    mode: 'onBlur'
  })

  const { data, isLoading, isError } = isEdit
    ? useFetchData('/categories/', id)
    : {
      data: null,
      isLoading: null,
      isError: null,
    }

  useEffect(() => {
    if (isEdit && data) {
      setValue('name', data?.name)
      setValue('cat_group', data.cat_group && data.cat_group.id)
      setValue('lot_group', data.lot_group && data.lot_group.id)
    }
  }, [data, isEdit]);

  const onSubmit = async (data) => {
    setLoading(true)
    data['operation'] = Number(operationId)

    let response = await sendAddCategory(data)
    if (response) {
      if (response.status === 201) {
        handleClose()
        enqueueSnackbar(dictionary["category_created"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  const onSubmitEdit = async (data) => {
    setLoading(true)
    data['operation'] = Number(operationId)
    let response = await updateCategory(data, id)
    if (response) {
      if (response.status === 200) {
        handleClose()
        enqueueSnackbar(dictionary["category_edited"], {
          variant: "success"
        })
        mutate(fetchUrl)
      }
      if (response.status === 400) {
        setLoading(false)
        const { errors } = response.data
        if (errors.length > 0) {
          errors.map((error) => {
            setError(error.field, {
              message: error.message,
            })
          })
        }
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit(isEdit ? onSubmitEdit : onSubmit)}
    >
      <Grid container>

        {/* Name */}
        <Grid xs="12">

          <TextField
            variant="outlined"
            required
            margin="normal"
            fullWidth
            id="name"
            label={dictionary["name"]}
            name="name"
            inputRef={register}
            error={errors.name}
            helperText={errors.name && errors.name.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid xs="12">

          <Select
            variant="outlined"
            margin="normal"
            fullWidth
            id="cat_group"
            label={dictionary["class"]}
            name="cat_group"
            inputRef={register}
            error={errors.cat_group}
            control={control}
            defaultValue=''
            helperText={errors.cat_group && errors.cat_group.message}
          >
            {categoriesGroup?.map(group => (
              <MenuItem key={group?.id} value={group?.id}>{group?.name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid xs="12">
          <Select
            variant="outlined"
            margin="normal"
            fullWidth
            id="lot_group"
            label={dictionary["lot_group"]}
            name="lot_group"
            inputRef={register}
            error={errors.lot_group}
            control={control}
            defaultValue=''
            helperText={errors.lot_group && errors.lot_group.message}
          >
            {lotGroups?.map(group => (
              <MenuItem key={group?.id} value={group?.id}>{group?.name}</MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid xs="12" className={classes.buttonContainer}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={loading}
            startIcon={<SaveIcon />}
            className={classes.submit}
          >
            {dictionary["submit"]}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
