import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import { Copyright, Layout, StickyHeader } from '@components/common'
import { BudgetsTableCategories } from './index'


import { useOperation } from "@contexts/operation"
import { useTranslation } from '@contexts/translation'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  line: {
    display: 'flex',
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  }
}))

export default function BudgetsCategoriesView({ operationId }) {
  const { dictionary } = useTranslation()
  const classes = useStyles()
  const { operation } = useOperation()

  return (
    <Layout isSidebar operationId={operationId}>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={3}>
          <StickyHeader
            operationName={operation?.display}
            breadcrumb={[
              { name: dictionary["operations"], href: `/operations/` },
              { name: operation?.name, href: `/operations/${operationId}` },
              { name: dictionary["budgets"], href: `/operations/${operationId}/budgets` },
              { name: dictionary["categories"] }
            ]} />
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <BudgetsTableCategories
                operationId={operationId}
              />
            </Paper>
          </Grid>
        </Grid>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </Layout>
  )
}
